import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataPagePresenterService } from '../../../../Services/presenter/data-page-presenter.service';
import { takeUntil } from 'rxjs/operators';
import { ICarouselItem } from '../../../../Component/home-page/carousel-home/carousel-home.model';
import { NguCarouselConfig } from '@ngu/carousel';
import { HomeService } from '../../services';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-home-sections',
	templateUrl: './home-sections.component.html',
	styleUrls: [ './home-sections.component.scss' ]
})
export class HomeSectionsComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new Subject<void>();

	carouselEventsImages: ICarouselItem[] = [];
	carouselEventsConfig: NguCarouselConfig = {
		grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
		speed: 800,
		point: { visible: true },
		touch: true,
		loop: true,
		interval: { timing: 4000 }
	};

	constructor(public dataPagePresenterService: DataPagePresenterService, private _homeService: HomeService) {
	}

	ngOnInit(): void {
		this.getBanners();
	}

	private getBanners() {
		this._homeService
				.getBannersHome()
				.pipe(takeUntil(this._unsubscribe$))
				.subscribe((data) => {
					if (data?.result?.length > 0) {
						this.carouselEventsImages = data.result.map((item) => ({
							desktopLogo: item.imageLink,
							mobileLogo: item.mobileImageLink,
							redirectUrl: item?.redirectUrl ?? '',
							alt: ''
						}));
					}
				});
	}

	ngOnDestroy() {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}
}
