<section class="bg-white position-relative">
	<div class="detail-banner position-relative">
		<img [src]="detail?.bannerUrl"
		     [alt]="detail?.title"
		     loading="eager"
		     class="img-fluid w-100 h-100 object-fit-cover">
		<div class="bg-black opacity-50 position-absolute top-0 end-0 bottom-0 start-0 z-2"></div>
		<div class="container position-absolute top-0 end-0 bottom-0 start-0 py-3 py-lg-4 py-xl-5 mx-auto z-3">
			<div class="row">
				<div class="col col-md-6 col-lg-7">
					<div class="d-flex flex-column align-items-start mt-2 mt-lg-0">
						<div class="d-flex column-gap-3">
							<button class="btn btn-sm btn-outline-light" type="button" (click)="onShare()">
								Compartir<img src="assets/svg/share.svg" alt="" class="ms-1">
							</button>
							<div class="badge text-white text-uppercase fw-normal align-content-center">
								CON {{ detail?.airline }}
							</div>
						</div>
						<h1 class="inter-bold text-white mt-3 mt-lg-4 me-3 me-md-0">
							{{ detail?.title }}
							<span class="badge text-white text-uppercase align-top align-content-center ms-md-2">
						{{ detail?.nights }}N
					</span>
						</h1>
						<p class="text-white fs-5">
							<img src="assets/social/location.svg" alt="" class="align-content-around" loading="lazy">
							{{ detail?.description }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container detail-content position-relative py-3 py-lg-4 py-xl-5">
		<div class="row gy-4 gy-lg-0 gx-lg-5">
			<div class="col-12 col-md-6 col-lg-7 order-1 order-lg-0">
				<div class="d-flex flex-wrap flex-lg-nowrap align-items-center row-gap-2 column-gap-2">
					<h5 class="fw-bold text-secondary mb-0 w-auto">Incluye</h5>
					<div class="d-flex flex-wrap flex-lg-nowrap row-gap-2 column-gap-2">
						<div class="badge badge-detail text-secondary border border-secondary bg-transparent h-100 p-2"
						     ngbTooltip="Pasajeros"
						     placement="bottom">
							<img src="assets/svg/user-gray.svg" alt="Pasajeros" class="me-1 align-middle" height="16"> 2
						</div>
						<div class="badge badge-detail text-secondary border border-secondary bg-transparent h-100 p-2"
						     ngbTooltip="Noches"
						     placement="bottom">
							<img src="assets/svg/night-gray.svg" alt="Noches" class="me-1 align-middle" height="16">
							{{ detail?.nights }}
						</div>
						<div *ngIf="detail?.includes?.transport"
						     class="badge badge-detail text-secondary border border-secondary bg-transparent h-100 p-2"
						     ngbTooltip="Transporte"
						     placement="bottom">
							<img src="assets/svg/plane-gray.svg" alt="Vuelos" class="me-1 align-middle" height="16">
							{{ detail?.includes?.transport }}
						</div>
						<div *ngIf="detail?.includes?.accommodation"
						     class="badge badge-detail text-secondary border border-secondary bg-transparent h-100 p-2"
						     ngbTooltip="Alojamientos"
						     placement="bottom">
							<img src="assets/svg/accommodation-gray.svg" alt="Alojamiento" class="me-1 align-middle" height="15">
							{{ detail?.includes?.accommodation }}
						</div>
						<div *ngIf="detail?.includes?.transfers"
						     class="badge badge-detail text-secondary border border-secondary bg-transparent h-100 p-2"
						     ngbTooltip="Traslados"
						     placement="bottom">
							<img src="assets/svg/transfer-gray.svg" alt="Traslados" class="me-1 align-middle" height="16">
							{{ detail?.includes?.transfers }}
						</div>
						<div *ngIf="detail?.includes?.insurances"
						     class="badge badge-detail text-secondary border border-secondary bg-transparent h-100 p-2"
						     ngbTooltip="Seguros"
						     placement="bottom">
							<img src="assets/svg/insurance-gray.svg" alt="Seguro" class="me-1 align-middle" height="16">
							{{ detail?.includes?.insurances }}
						</div>
						<div *ngIf="detail?.includes?.allInclusive"
						     class="badge badge-detail text-secondary border border-secondary bg-transparent h-100 p-2"
						     ngbTooltip="All Inclusive"
						     placement="bottom">
							<img src="assets/svg/all-inclusive-gray.svg" alt="All Inclusive" class="me-1 align-middle" height="16">
							{{ detail?.includes?.allInclusive }}
						</div>
						<div *ngIf="detail?.includes?.breakfast"
						     class="badge badge-detail text-secondary border border-secondary bg-transparent h-100 p-2"
						     ngbTooltip="Desayuno"
						     placement="bottom">
							<img src="assets/svg/breakfast-gray.svg" alt="Desayuno" class="me-1 align-middle" height="16">
							{{ detail?.includes?.breakfast }}
						</div>
						<div *ngIf="detail?.includes?.baggage"
						     class="badge badge-detail text-secondary border border-secondary bg-transparent h-100 p-2"
						     ngbTooltip="Equipaje en bodega"
						     placement="bottom">
							<img src="assets/svg/baggage-gray.svg" alt="Equipaje" class="me-1 align-middle" height="16">
							{{ detail?.includes?.baggage }}
						</div>
					</div>
				</div>
				<h5 class="fw-bold text-secondary mt-4 mb-0 mt-lg-5 mb-lg-4">Itinerario</h5>
				<div class="d-flex flex-column">
					<div *ngFor="let itinerary of detail?.itinerary; let i = index"
					     class="d-flex align-items-center itinerary-item mb-2">
						<div [ngClass]="{ 'border-secondary text-secondary': i === 0 || i === detail!.itinerary!.length - 1 }"
						     class="badge d-flex flex-column fs-5 bg-transparent border border-3 py-3 px-2">
							<span class="fw-bold fs-4">{{ itinerary.day }}</span>
							<span class="fw-medium">{{ itinerary.month }}</span>
						</div>
						<p class="border-bottom fs-5 text-secondary flex-grow-1 py-3 py-xl-4 mb-0 ms-3 ms-lg-4"
						   [innerHTML]="itinerary.description"></p>
					</div>
				</div>
				<h5 class="fw-bold text-secondary my-4 mt-lg-5" *ngIf="detail?.important?.length">Importante</h5>
				<ng-container *ngFor="let important of detail?.important">
					<a [href]="important.url"
					   *ngIf="important.url"
					   target="_blank"
					   class="text-secondary text-decoration-none fs-6 fw-normal d-block mb-3">
						- {{ important.description }}
					</a>
					<p class="text-secondary text-decoration-none fs-6 fw-normal d-block mb-3">- {{ important.description }}</p>
				</ng-container>
				<h5 class="fw-bold text-secondary my-4 mt-lg-5">Impuestos hoteleros</h5>
				<p class="text-secondary">{{ detail?.hotelFees }}</p>
				<h5 class="fw-bold text-secondary my-4 mt-lg-5" *ngIf="detail?.serviceFees">Impuestos de servicio</h5>
				<p class="text-secondary">{{ detail?.serviceFees }}</p>
				<div *ngIf="detail?.pdfUrl" class="row mt-4 mt-lg-5">
					<div class="col col-md-5 col-lg-4 ms-auto">
						<app-button [left]="true"
						            [icon]="'pdf-red.svg'"
						            (click)="redirectToPDF()"
						            color="outline-primary"
						            text="Descargar PDF"></app-button>
					</div>
				</div>
			</div>
			<div class="col right-col order-0 order-lg-1 z-3">
				<div class="card border-0 shadow">
					<div class="card-body d-flex flex-column px-xl-4">
						<p class="text-secondary mb-0">Precio por 2 pasajeros desde</p>
						<p class="fs-6 inter-semi-bold text-body-tertiary text-decoration-line-through my-1" *ngIf="discount">
							{{ price | currency:'USD':'US$ ':'1.0-0' }}
						</p>
						<h4 class="display-5 inter-bold" *ngIf="discount">
							{{ discount.newPrice | currency:'USD':'US$ ':'1.0-0' }}
						</h4>
						<h4 class="display-5 inter-bold" *ngIf="!discount">
							{{ price | currency:'USD':'US$ ':'1.0-0' }}
						</h4>
						<app-select
							[label]="''"
							[placeholder]="'Tipo de habitación'"
							[listItems]="roomTypeItems"
							[name]="'room-type'"
							[default]="roomType"
							[isEmitChanges]="true"
							(selectedItem)="onRoomTypeChange($event)"
							*ngIf="roomTypeItems.length > 0"
							class="mb-2 mb-lg-3"
						></app-select>
						<app-select
							[label]="''"
							[placeholder]="'Elegir salida fija'"
							[listItems]="dateItems"
							[name]="'fixed-date'"
							[default]="selectedDate"
							[isEmitChanges]="true"
							*ngIf="dateItems.length > 0"
						></app-select>
						<div class="d-grid mt-3 mt-lg-4">
							<app-custom-button [btnBlock]="true"
							                   [btnOutline]="true"
							                   (btnClick)="openContactModal()"
							                   btnClass="buy-offer"
							                   btnId="buyOffer"
							                   label="Comprar oferta"></app-custom-button>
						</div>
					</div>
				</div>
				<div class="row row-cols-2 mt-3 mt-lg-4 px-3 gy-3">
					<div *ngFor="let galleryItem of detail?.gallery" class="col">
						<img [src]="galleryItem"
						     (click)="onSelectImage(galleryItem)"
						     alt="Galería"
						     class="img-fluid img-rounded object-fit-cover shadow-sm"
						     loading="lazy">
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<app-lightbox [showLightbox]="isLightboxVisible"
              [imageSrc]="selectedImage"
              (close)="isLightboxVisible = false"></app-lightbox>