import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-card-placeholder',
	templateUrl: './card-placeholder.component.html',
	styleUrls: [ './card-placeholder.component.scss' ]
})
export class CardPlaceholderComponent implements OnInit {
	@Input() imgTopStyle: 'none' | 'sm' | 'md' | 'lg' = 'none';

	constructor() {
	}

	ngOnInit(): void {
	}

}
