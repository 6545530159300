import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomButtonComponent } from './custom-button.component';
import { RouterLink, RouterLinkWithHref } from '@angular/router';



@NgModule({
	declarations: [
		CustomButtonComponent
	],
	exports: [
		CustomButtonComponent
	],
	imports: [
		CommonModule,
		RouterLink,
		RouterLinkWithHref
	]
})
export class CustomButtonModule { }
