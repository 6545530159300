<nmviajes-loading></nmviajes-loading>
<router-outlet></router-outlet>
<app-notificacion-error></app-notificacion-error>
<a id="btn-whatsapp"
    class="position-fixed text-end rounded-start"
    href="https://api.whatsapp.com/send?phone=51919294628"
    target="_blank"
    rel="noreferrer noopenner"
    role="button"
    matTooltip="Cont&aacute;ctanos"
    matTooltipPosition="left"
    [matTooltipHideDelay]="2000">
  <img src="./assets/whatsapp-icon.svg" alt="Whatsapp chat" class="img-fluid"/>
</a>