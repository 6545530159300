import { Routes } from '@angular/router';

export const redirectRoutes: Routes = [
  { path: 'vuelos/iberia.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'Paquetes/DetallePaquete/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes-irresistibles/?utm_source=web&utm_medium=slider home 2&utm_campaign=paquetes_irresistibles', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/europa', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'politicas-privacidad', redirectTo: 'politicas', pathMatch: 'full' },
  { path: 'vuelos/internacionales/new-york.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/peru', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/internacionales/orlando.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/bay/bayahibe', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'travel-', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/internacionales/barcelona.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'peru/destinos/centro/ucayali/', redirectTo: 'actividades', pathMatch: 'full' },
  { path: 'tusdatossit', redirectTo: 'tusdatos', pathMatch: 'full' },
  { path: '28dejulio', redirectTo: '28-de-julio', pathMatch: 'full' },
  { path: 'webforms/hoteles/hoteles-ps.aspx', redirectTo: 'hoteles', pathMatch: 'full' },
  { path: 'filtro/autos', redirectTo: 'autos', pathMatch: 'full' },
  { path: 'vuelo hotel', redirectTo: 'vuelohotel', pathMatch: 'full' },
  { path: 'cyber-ofertas/', redirectTo: 'promociones', pathMatch: 'full' },
  { path: 'con', redirectTo: '', pathMatch: 'full' },
  { path: 'sit-traveloutlet/?cupon', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/zor/zorritos', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/internacionales/paris.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'LAN', redirectTo: '', pathMatch: 'full' },
  { path: 'datosvip', redirectTo: '', pathMatch: 'full' },
  { path: 'dias-', redirectTo: '', pathMatch: 'full' },
  { path: 'cyber', redirectTo: '', pathMatch: 'full' },
  { path: 'viajes/landingFooter?pOrigen=LIM&pDestino=BOG&pNombre=BOGOTA', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'viajes/landingFooter?pOrigen=LIM&pDestino=PIU&pNombre=PIURA', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'tusdatoscpSTOCK', redirectTo: 'tusdatos', pathMatch: 'full' },
  { path: 'paquetes/paquetes-turisticos-chachapoyas', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'viajes/landingFooter?pOrigen=LIM&pDestino=EZE&pNombre=EZEIZA BUENOS AIRES', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/igu-paquetes-a-foz-do-iguazu', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/vto-paquetes-a-vichayito?c=1', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'viajes/landingFooter?pOrigen=LIM&pDestino=CUZ&pNombre=CUSCO', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'viajes/landingFooter?pOrigen=LIM&pDestino=GIG&pNombre=RIO DE JANEIRO', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'viajes/landingFooter?pOrigen=LIM&pDestino=PTY&pNombre=PANAM%C3%81', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'travel-sale', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'viajes/landingFooter?pOrigen=LIM&pDestino=CTG&pNombre=CARTAGENA', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/sudamerica', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'viajes/landingFooter?pOrigen=LIM&pDestino=TPP&pNombre=TARAPOTO', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/paquetes-turisticos-riviera-maya', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'maravillas-del-peru', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'peru/destinos/sur/ayacucho/', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/internacionales/sao-paulo.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/maa-paquetes-a-mancora-?c=1', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/asia', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/internacionales/cali.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'ponte-modo-avion/', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'terminos', redirectTo: 'terminos-condiciones', pathMatch: 'full' },
  { path: 'orlando-', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'tusdatoscp', redirectTo: 'tusdatos', pathMatch: 'full' },
  { path: 'peru/', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/tpp-paquetes-a-tarapoto/314-redescubre-peru/diciembre/2-noches', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'carnaval-de-ofertas', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'cybernmviajes?utm_source=facebook&utm_medium=anuncio&utm_campaign=cybernmviajes&utm_term=paquetes&utm_content=album', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'tusdatossit?1', redirectTo: 'tusdatos', pathMatch: 'full' },
  { path: 'vuelos/starperu.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'nmsale/', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'Sites/Ubicacion/', redirectTo: 'nuestras-agencias', pathMatch: 'full' },
  { path: 'filtro/armapaquete', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'peru/viajes/', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'resources/pdf/RSC_Nuevo_Mundo_Viajes.pdf', redirectTo: 'responsabilidad-social', pathMatch: 'full' },
  { path: 'banbif', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'cyber-scotiabank?utm_source=web&utm_medium=home_slider&utm_campaign=cyberscotiabank&utm_term=cartagena', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/Internacionales/iquique.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'peru/experiencias/cultural/huacas-del-sol-y-de-la-luna/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/LIM-paquetes-a-lima/1-noches', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'tusdatosCP', redirectTo: 'tusdatos', pathMatch: 'full' },
  { path: 'Widgets?Type=PAQUETE-DESTINO&hdCiudad=VTO', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/paquetes-turisticos-puerto-aventuras', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'tus', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/tpp-paquetes-a-tarapoto/71-vacaciones-confirmadas-nacional/enero/2-noches', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/iqt-paquetes-a-iquitos', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/vto-paquetes-a-vichayito?c=1/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/peruvian-airlines.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'alienta-a-tu-seleccion', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/destino/BOD', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/ayp/ayacucho', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'nuestras-', redirectTo: 'nuestras-agencias', pathMatch: 'full' },
  { path: 'orlando-disney/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/norteamerica/vuelos-estados-unidos/vuelos-lima-erie-eri', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'Sites/Ubicacion', redirectTo: 'nuestras-agencias', pathMatch: 'full' },
  { path: 'cyber-', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/pcl-paquetes-a-pucallpa/314-redescubre-peru', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'cierra-', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'cyberwow', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'MiPerfil/GenerarAsesoriaInmediata', redirectTo: 'perfil', pathMatch: 'full' },
  { path: 'sit/ofertas-', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/pta-paquetes-a-punta-sal', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'Viajes', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/pdc/playa-del-carmen', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'peru/wp-content/uploads/2020/07/Santa-Catalina-', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'traveloutlet-sit/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/punta-sal', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/pcl-paquetes-a-pucallpa?utm_source=web&utm_medium=landing&utm_campaign=payu-visa&utm_term=paquetes&utm_content=pucallpa', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'solicitud-', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'peru/experiencias/naturaleza/manglares/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'interbank/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'sit-online', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/internacionales/los-angeles.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/europa/vuelos-reino-unido/vuelos-lima-londres-lon', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'traveloutlet/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'agencias-de-viajes-nuevo-', redirectTo: 'nuestras-agencias', pathMatch: 'full' },
  { path: 'sit-online/', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/392-giftcard-nm', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/air-france.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'filtro/vuelohotel', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/peru', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/asia', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/77-semana-santa-nacional/abril', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'tusdatoscp?utm_source=Facebook&utm_medium=CPC&utm_campaign=Post%20NM%2005-10-17', redirectTo: 'tusdatos', pathMatch: 'full' },
  { path: 'cyberday', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'nuestras-agencia', redirectTo: 'nuestras-agencias', pathMatch: 'full' },
  { path: 'paquetes/ofertas/prc-paquetes-a-paracas/agosto/2-noches', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'peru/experiencias/aventura/volcan-misti/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'cybernmviajes/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'liquidacion/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/maa-paquetes-a-mancora?utm_source=facebook&utm_medium=referral&utm_campaign=playas-escapa', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vecino-miraflores', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/paquetes-turisticos-cajamarca', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/american-airlines.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/internacionales/moscow.aspx/', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/vuelos-', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'tuscitas/Agenda-', redirectTo: 'tuscitas', pathMatch: 'full' },
  { path: 'paquetes/ofertas/pdc-paquetes-a-playa-del-carmen/noviembre', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'Condiciones_United_Airlines.html', redirectTo: 'condiciones-de-reserva', pathMatch: 'full' },
  { path: 'paquetes/ofertas/ams-paquetes-a-amsterdam/190-vacaciones-confirmadas-cruceros/julio/4-noches', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/pdc-paquetes-a-playa-del-carmen?utm_source=facebook&utm_medium=referral&utm_campaign=caribe&utm_id=caribe&utm_term=playa_carmen&utm_content=playa_carmen', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/rio-paquetes-a-rio-de-janeiro?c=1/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'liquidacion', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/norteamerica/vuelos-estados-unidos/vuelos-lima-sarasota-bradenton-srq', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vive-tu-sueno/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'librosreclamo', redirectTo: 'libro-reclamaciones', pathMatch: 'full' },
  { path: 'Boletines/GrabarSuscripcion', redirectTo: 'suscribirse', pathMatch: 'full' },
  { path: 'sit/ofertas-sit', redirectTo: 'ofertas', pathMatch: 'full' },
  { path: 'disney/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/lineaaerea/9V', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'peru/experiencias/cultural/la-ruta-del-pisco/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/air-france.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'adelanta-tu-cyber', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/peru?utm_source=Facebook&utm_medium=CPC&utm_campaign=Post+NM+25-08-17', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'viajes/landingFooter?pOrigen=LIM&pDestino=NYC&pNombre=NEW%20YORK', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/228-fiestas-patrias-caribe/2-noches', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/tpp?gclid=EAIaIQobChMIspnow__v5gIVDVPBCh2HSAqcEAEYASACEgL3vfD_BwE', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/internacionales/panama.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/destino', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'peru/experiencias/cultural/chincha/', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/internacionales/buenos-aires.aspx', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'peru/experiencias/naturaleza/cerros-de-amotape/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'peru/destinos/norte/cajamarca/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/320-lollapaloza-santiago-2020', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'peru/experiencias/aventura/lago-titicaca/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'filtro/actividades', redirectTo: 'actividades', pathMatch: 'full' },
  { path: 'vuelos/destino/PAR', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/aua-paquetes-a-aruba?utm_source=NMViajes&utm_campaign=e372ff4dbc-BL-Camp-Martes-%28Aruba%29-06%2F08%2F19&utm_medium=email&utm_term=0_c27e3fdb0f-e372ff4dbc-&ct=t(EMAIL_CAMPAIGN_8_6_2019_11_29)&goal=0_c27e3fdb0f-e372ff4dbc-&mc_cid=e372ff4dbc&mc_eid=%5BUNIQID%5D', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/vuelos-nacionales/pucallpa.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/iqt/iquitos', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/cja/cajamarca', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/cuz/cusco', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/cix/chiclayo', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/pvr/puerto-vallarta', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/mex/mexico', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/ayp-paquetes-a-ayacucho', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/norteamerica/vuelos-estados-unidos/vuelos-lima-gulfport-biloxi-gpt', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'trabaja-con-nosotros/registro', redirectTo: 'nuestra-empresa', pathMatch: 'full' },
  { path: 'vuelos/medio-oriente', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'Widgets?Type=PAQUETE-DESTINO&hdCiudad=ZOR', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'librosreclamo/imprimir?id=FC17AC0D47549ED6', redirectTo: 'libro-reclamaciones', pathMatch: 'full' },
  { path: 'mama-viajera/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'peru/destinos/centro/ica/', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'Widgets?Type=PAQUETE-DESTINO&hdCiudad=JUL', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/internacionales/moscu.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'Vuelos/Resultados?parSource=LIM&parTarget=PDP&parPais=UY&parRegion=SA&parSem=4&parDias=0&parVuelo=Int&parPas=1&parCls=Y&parRango=A&CA=1&CN=0&CI=0&FS=04/11/2019&FR=11/11/2019', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/vto-paquetes-a-vichayito/?utm_source=facebook&utm_medium=referall&utm_campaign=cybernmviajes&utm_term=album-facebook&utm_content=album-facebook', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/pdc-paquetes-a-playa-del-carmen/228-fiestas-patrias-caribe', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/pcl-paquetes-a-pucallpa/279-cyber-wow', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'peru/experiencias/playa/huanchaco/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/vuelos-nacionales/cusco.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/aua-paquetes-a-aruba/mayo', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/norteamerica/vuelos-estados-unidos/vuelos-lima-santa-fe-saf', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/vto-paquetes-a-vichayito/377-nmsale/3-noches', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'dias-nmviajes/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/Lufthansa.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'semana-santa?utm_source=web&utm_medium=home_slider&utm_campaign=verano%202020&utm_term=semana-santa', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'Widgets?Type=PAQUETE-DESTINO&hdCiudad=PCL', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'tusviajes', redirectTo: 'perfil/mis-reservas-vuelos', pathMatch: 'full' },
  { path: 'bbvadays', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'peru/destinos/norte/san-martin/', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/sudamerica/vuelos-brasil/vuelos-lima-joao-pessoa-jpa', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/oceania', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'peru/experiencias/cultural/kotosh/', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'filtro/hoteles', redirectTo: 'hoteles', pathMatch: 'full' },
  { path: 'ofertas-de-primavera/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/norteamerica/vuelos-estados-unidos/vuelos-lima-philadelphia-phl', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/bay-paquetes-a-bayahibe/377-nmsale/4-noches', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/africa/vuelos-senegal/vuelos-lima-dakar-yoff-dkr', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/klm.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/tam.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/par-paquetes-a-paris/177-vacaciones-confirmadas-europa', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/norteamerica', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'air-canada/', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'peru/experiencias/naturaleza/catarata-de-gocta/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/rvm-paquetes-a-riviera-maya/229-fiestas-patrias-sudamerica/julio/3-noches', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/junio', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/noviembre', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/europa/vuelos-suiza/vuelos-lima-bern-berne-bern-belp-brn', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'viaja-tour-', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/hua/huaraz', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/africa', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'filtro/hoteles?utm_source=landing_descubre&utm_medium=banner&utm_campaign=blog&utm_id=blog&utm_term=banner_hoteles&utm_content=banner_hoteles', redirectTo: 'hoteles', pathMatch: 'full' },
  { path: 'paquetes/ofertas/octubre', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'viajes-', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/cal/caral', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/vuelos-nacionales/chiclayo.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/internacionales/Amsterdam.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/internacionales/londres.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/pdc-paquetes-a-playa-del-carmen/224-vacaciones-confirmadas-caribe/noviembre', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'paquetes/ofertas/naz/nasca', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/delta-air-lines.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/destino/lim/aqp', redirectTo: 'vuelos/destino/LIM/AQP', pathMatch: 'full' },
  { path: 'vuelos/vuelos-nacionales/jauja.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/internacionales/madrid.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/paquetes-turisticos-tarapoto', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/lcperu.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'descubre-belice/', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'vuelos/internacionales/moscow.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'scotiabank', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'vuelos/lan.aspx', redirectTo: 'vuelos', pathMatch: 'full' },
  { path: 'paquetes/ofertas/ica-paquetes-a-ica/314-redescubre-peru/febrero', redirectTo: 'paquetes', pathMatch: 'full' },
  { path: 'resources/pdf/Pol%C3%ADtica_Protecci%C3%B3n_Tratamiento_de_Datos_Personales_NMVIAJES.pdf?637668542907887897', redirectTo: 'terminos-condiciones', pathMatch: 'full' },
  { path: 'resources/pdf/Pol%C3%ADtica_Protecci%C3%B3n_Tratamiento_de_Datos_Personales_NMVIAJES.pdf?637420069754409245', redirectTo: 'terminos-condiciones', pathMatch: 'full' },
];