import { Injectable } from '@angular/core';
import { RToken } from '../models/rq-token-ce-request';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { DEVICES } from '../../../core/constants';
import { newGuid } from '../../../shared/utils/guid.utils';
import { catchError, tap } from 'rxjs/operators';
import { ExchangeRate } from '../../api-checkout/models/rq-checkout-search';

@Injectable({ providedIn: 'root' })
export class TokenService {
	private _exchangeRate = new BehaviorSubject<ExchangeRate | null>(null);

	constructor(private _httpClient: HttpClient) {
	}

	get exchangeRate$(): Observable<ExchangeRate | null> {
		return this._exchangeRate.asObservable();
	}

	setExchangeRate(value: ExchangeRate | null) {
		this._exchangeRate.next(value);
	}

	getAndSaveToken(browser: string): Observable<RToken> {
		const credentials = localStorage.getItem('usuario');
		let req = {
			trackingCode: newGuid(),
			muteExceptions: true,
			caller: {
				company: 'Expertia',
				application: 'NMViajes',
				fromIP: '',
				fromBrowser: browser
			},
			webId: 7,
			device: DEVICES.WEB,
			userCode: parseInt(credentials ? JSON.parse(credentials).id : '0')
		};

		let url = `${environment.urlApiMotorVuelos}/auth/api/auth/token`;
		return this._httpClient.post<RToken>(url, req, {
			headers: {
				ignoreInterceptor: 'true',
				'not-loading': 'true'
			}
		}).pipe(
				tap(response => {
					sessionStorage.setItem('AUTH_LINKS', JSON.stringify(response.enlacesWeb));
					this.setExchangeRate(response.exchangeRate);
				}),
				catchError(err => {
					this.setExchangeRate(null);
					return throwError(err);
				})
		);
	}

	getTokenByTransactionId(transactionId: string, ipAddress: string) {
		let url = `${environment.urlApiMotorVuelos}/auth/api/auth/meta-token/${transactionId}/${ipAddress}`;
		return this._httpClient.post<RToken>(url, {});
	}

	public getIPAddress() {
		return this._httpClient.get<any>('https://api.ipify.org/?format=json');
	}
}
