<div class="card border-0 shadow overflow-hidden h-100">
	<div class="card-img-top">
		<img [src]="item.imgUrl"
		     [alt]="item.title"
		     loading="lazy"
		     class="img-fluid object-fit-cover w-100 h-100">
	</div>
	<div class="card-body px-lg-4 position-relative d-flex flex-column">
		<div class="badge rounded-start-0 rounded-end-3 text-bg-primary fs-6 position-absolute start-0 py-2 px-4">
			{{ item.days }} D&iacute;as / {{ item.nights }} Noches
		</div>
		<div class="d-flex flex-nowrap justify-content-between">
			<h3 class="card-title inter-bold text-wrap mt-3">{{ item.title }}</h3>
			<small class="mt-1 inter-medium">{{ item.tag }}</small>
		</div>
		<p class="text-secondary inter-medium text-uppercase lh-1 mb-2">DESDE {{ item.origin.split('(')[0] }}</p>
		<p class="small inter-bold text-uppercase flex-grow-1 mb-0">{{ item.airline }}</p>
		<p class="small text-end text-secondary inter-medium" [class.mb-0]="item.discount" [class.mb-1]="!item.discount">
			Precio desde
		</p>
		<p class="text-end fs-6 text-body-tertiary inter-medium text-decoration-line-through mb-0" *ngIf="item.discount">
			US$ {{ item.price }}
		</p>
		<p class="display-6 text-end inter-bold mb-0" *ngIf="item.discount">US$ {{ item.discount?.newPrice }}</p>
		<p class="display-6 text-end inter-bold mb-0" *ngIf="!item.discount">US$ {{ item.price }}</p>
		<p class="small text-end text-secondary inter-medium">para 2 pasajeros</p>
		<app-custom-button [btnBlock]="true"
		                   (btnClick)="onClick()"
		                   btnClass="offer-card-button"
		                   label="¡Lo quiero!"></app-custom-button>
	</div>
</div>
