import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReplacePipe } from '../../pipes/replace.pipe';
import { NewcardOfferComponent } from './newcard-offer.component';
import { CustomButtonModule } from '../custom-button/custom-button.module';

@NgModule({
	imports: [ CommonModule, CustomButtonModule ],
	exports: [ NewcardOfferComponent ],
	declarations: [ NewcardOfferComponent, ReplacePipe ],
	providers: []
})
export class NewCardOfferModule {
}
