export const DEVICES = {
	ANDROID: 1,
	IOS: 2,
	WEB: 3,
	VIAJALA: 7,
	KAYAK: 10,
	TURISMO_CITY: 14,
	WHATSAPP: 18
} as const;

export const PLATFORM_STORAGE_KEYS = {
	MOBILE: 'mobile',
	DEVICE: 'device'
};