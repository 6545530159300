import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Offer } from 'src/app/Models/offers/offers.model';
import { OffersService } from 'src/app/Services/offers/offers.service';
import { finalize, take } from 'rxjs/operators';

@Component({
	selector: 'app-section-super-offers',
	templateUrl: './section-super-offers.component.html',
	styleUrls: [ './section-super-offers.component.scss' ]
})
export class SectionSuperOffersComponent implements OnInit {
	protected _isBrowser: Boolean = false;
	private _currentPagNac = 0;
	private _currentPagInc = 0;

	isLoading = false;
	placeholderItems = Array(4).fill(0).map((x, i) => i);

	indexTab = 0;

	internationalFlights: Offer[] = [];
	nationalFlights: Offer[] = [];

	internationalFlightsPag: Offer[] = [];
	nationalFlightsPag: Offer[] = [];

	showOffersNac = true;
	showOffersInt = true;

	constructor(
			private offersService: OffersService,
			private _router: Router,
			@Inject(PLATFORM_ID) private platformId: Object
	) {
		if (isPlatformBrowser(this.platformId)) this._isBrowser = true;
	}

	ngOnInit(): void {
		this.offersNacInt();
	}

	private offersNacInt() {
		this.isLoading = true;
		this.offersService.getOffersNationalInternational().pipe(
				take(1),
				finalize(() => {
					if (this._isBrowser) setTimeout(() => this.isLoading = false, 800);
				})
		).subscribe({
			next: (res) => {
				this.nationalFlights = res.Result.filter((item) => item.Type == 'NAC').slice(0, 8);
				this.nationalFlightsPag = this.nationalFlights.slice(0, 4);
				this.internationalFlights = res.Result.filter((item) => item.Type == 'INT').slice(0, 8);
				this.internationalFlightsPag = this.internationalFlights.slice(0, 4);
				if (this.nationalFlights.length == 0) this.showOffersNac = false;
				if (this.internationalFlights.length == 0) this.showOffersInt = false;
				this.updateDisplayedDataNac();
				this.updateDisplayedDataInc();
			},
			error: (err) => {
				this.showOffersNac = false;
				this.showOffersInt = false;
				console.error(err);
			}
		});
	}

	showMore() {
		if (this.indexTab == 0) this.updateDisplayedDataNac();
		if (this.indexTab == 1) this.updateDisplayedDataInc();
	}

	private updateDisplayedDataNac() {
		const currentPagNac = this._currentPagNac + 4;
		if (currentPagNac > this.nationalFlights.length) {
			this.nationalFlightsPag = this.nationalFlights.slice(0, 4);
			this._currentPagNac = 4;
			this.scrollReset();
		} else {
			this.nationalFlightsPag = this.nationalFlights.slice(0, currentPagNac);
			this._currentPagNac = currentPagNac;
		}
	}

	private updateDisplayedDataInc() {
		const currentPagInc = this._currentPagInc + 4;
		if (currentPagInc > this.internationalFlights.length) {
			this.internationalFlightsPag = this.internationalFlights.slice(0, 4);
			this._currentPagInc = 4;
			this.scrollReset();
		} else {
			this.internationalFlightsPag = this.internationalFlights.slice(0, currentPagInc);
			this._currentPagInc = currentPagInc;
		}
	}

	private scrollReset() {
		if (this._isBrowser)
			document.querySelector('#sectionOffers')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}
}
