import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardPlaceholderComponent } from './card-placeholder.component';
import { CustomButtonModule } from '../custom-button/custom-button.module';



@NgModule({
	declarations: [
		CardPlaceholderComponent
	],
	exports: [
		CardPlaceholderComponent
	],
	imports: [
		CommonModule,
		CustomButtonModule
	]
})
export class CardPlaceholderModule { }
